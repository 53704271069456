<template>
    <div class="footer">
        <div class="dob_container footer-container">
            <ul class="footer-item footer-item-logo">
                <a href="//www.dobweb.com">
                    <img :src="logo" title="德欧宝" class="footer-logo" />
                </a>
                <li class="tit"><a href="//www.dobweb.com">北京德欧宝科技有限公司</a></li>
            </ul>

            <ul class="footer-item">
                <li class="tit"><a>业务范畴</a></li>
                <li class="list"><a>政企信息化</a></li>
                <li class="list"><a>电子契约</a></li>
                <li class="list"><a>无纸化办公</a></li>
                <li class="list"><a>企业形象策划</a></li>
                <li class="list"><a>旅游资源开发</a></li>
            </ul>
            <!-- <ul class="footer-item">
                <li class="tit"><a>产品</a></li>
                <li class="list"><a>信息化平台</a></li>
                <li class="list"><a>多端商城</a></li>
                <li class="list"><a>班车小程序</a></li>
                <li class="list"><a>车况核验</a></li>
            </ul> -->
            
            <ul class="footer-item">
                <li class="tit"><a>行业热点</a></li>
                <li class="list"><a>互联网</a></li>
                <li class="list"><a>信息化</a></li>
                <li class="list"><a>人工智能</a></li>
            </ul>

            <ul class="footer-item">
                <li class="tit"><a>联系德欧宝</a></li>
                <li class="list"><a>业务需求</a></li>
                <li class="list"><a>投诉举报</a></li>
                <li class="list"><a>信息纠错</a></li>
            </ul>
        </div>

        <div class="dob_container footer-copyright">
            <span class="copy_en">Copyright © {{ year }} dob Inc. All rights reserved.</span> <span class="copy_cn">北京德欧宝科技有限公司 &copy; 版权所有</span> <span class="copy_ba">备案号：<a href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
                target="_blank">京ICP备19039091号-2</a></span>
            <div class="beian">
                <img class="img" src="../../assets/beian-ga.png" />
                <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11011302006817" rel="noreferrer" target="_blank">京公网安备11011302006817</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import logo from "@/assets/logo.png";
const year = new Date().getFullYear();
</script>

<style lang="less" scoped>
.footer {
    position: relative;
    z-index: 97;
    color: rgba(255, 255, 255, 0.75);

    .footer-container {
        padding: 80px 0 40px;
        display: flex;

        .footer-item {
            flex: 1;
            position: 0;
            margin: 0;
            list-style: none;
            text-align: left;

            .footer-logo {
                width: 64px;
                height: auto;
                margin-bottom: 1rem;
                margin-left: 4em;
            }

            .tit {
                font-size: 1.2rem;
                font-weight: bold;
                margin-bottom: 0.5em;
            }

            .list {
                font-size: 0.9rem;
                padding: 0; margin: 0;
                a {
                    display: inline-block;
                    padding: 0.5rem 0;
                    color: rgba(255, 255, 255, 0.55);
                    &:hover {
                        color: rgba(255, 255, 255, 0.95);
                    }
                }
            }

            a {
                cursor: pointer;
            }
        }

        .footer-item-logo {
            flex: 2;
            a {text-decoration: none;}
        }
    }

    .footer-copyright {
        padding-bottom: 40px;
        font-size: 0.8rem;
        color: rgba(255, 255, 255, 0.35);
        user-select: none;
        a {
            color: rgba(#669933, 1);
        }
        .beian {
            padding-top: .5rem;
            img {width: 18px; margin-right: .5em; vertical-align: bottom;}
        }
    }

    a {
        color: rgba(255, 255, 255, 0.85);

        &:hover {
            color: rgba(255, 255, 255, 1);
            text-decoration: underline;
        }
    }
}

@media only screen and (max-width: 414px) {
    .footer {
        padding: 2rem 0;
        .footer-container {
            display: none;
            .footer-item {
                flex: 1;
                .footer-logo {
                    width: 48px;
                    height: auto;
                    margin-left: 0;
                }
            }
            .footer-item-logo {
                .tit {display: none}
            }

        }
        .footer-copyright {
            padding: 1rem;
            width: 21em;
        }
    }
}
</style>
