<template>
    <router-view />
</template>

<script setup>
import { onBeforeUnmount } from 'vue'
import performancekit from 'performance-kits';
import router from './router'

const { onloadPerformance, switchPerformance, closePerformance } = performancekit;

// onloadPerformance().then((obj) => {
//     console.log('onloadPerformance', obj);
// });

const onSwitchPerformance = () => {
    switchPerformance().then(obj => {
        console.log('switchPerformance', obj)
    })
}

const onClosePerformance = () => {
    closePerformance().then(obj => {
        console.log('closePerformance', obj)
    })
}

router.beforeEach((to, from, next) => {
  onSwitchPerformance()
  next(); // 必须调用next()来resolve这个钩子
});

onBeforeUnmount(onClosePerformance)
</script>

<style>
</style>
